import React, { useEffect, useState } from 'react';
import { Button, Grid, makeStyles, createStyles, Typography, Checkbox } from '@material-ui/core';
import { ICasinoLobbyV2, IGetGameRequestDto } from '../../models/CasinoLobbyV2/CasinoLobbyDto';
import { ILoginResponse, Right } from '../../models/login';
import { TPIProviderCategoryEnum } from '../../enum/tpi/TPIProviderCategoryEnum';
import { isAndroid, isIOS, isWinPhone } from 'react-device-detect';
import { LiveDealerViewEnum } from '../../enum/tpi/LiveDealerView';
import { Loading } from '../../shared/loading/loading.component';
import { getAppSettings } from '../../utils';
import { TPIProviderTypeEnum } from '../../enum/tpi/TPIProviderTypeEnum';
import { LiveDealerDisplayIframeComponent } from './components/live-dealer-display-iframe.component';
import { LiveDealerDisplayProvider } from './components/live-dealer-display-provider.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GetAmountWithOutDecimals } from '../../shared/functions/common';

const useStyles: any = makeStyles((theme: any) =>
  createStyles({
    root: {
      paddingTop: '24px',
      paddingBottom: '24px',
      width: '100%',
    },
    disabledA: {
      pointerEvents: 'none',
      cursor: 'default',
    },
    containerHref: {
      height: '100px',
      lineHeight: '100px',
    },
    description: {
      color: '#E3DAC9',
    },
    container: {
      width: '75%',
      margin: 'auto',
      [theme.breakpoints.down('md')]: {
        width: '90%',
      },
      [theme.breakpoints.up('md')]: {
        marginBottom: '30px',
      },
    },
    providerImage: {
      maxWidth: '150px',
      height: 'auto',
      maxHeight: '50px',
      width: '100%',
      marginBottom: '10px',
    },
    providerImageCard: {
      width: '100%',
      [theme.breakpoints.down('md')]: {
        width: '75%',
      },
    },
    bottonBack: {
      margin: 'auto',
      width: '100%%',
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        marginTop: '0.5rem',
        marginBottom: '30px',
      },
    },
    divProviderImage: {
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      },
    },
    useFreeplayBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      border: '4px solid #c8a964',
      borderRadius: '4px',
      maxWidth: '300px',
      marginLeft: 'auto',
      padding: '1px',
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
      },
    },
    useFreePlayBalanceMobile: {
      width: '100%',
      marginTop: '2px',
      marginBottom: '10px',
    },
    textAC: {
      textAlign: 'center',
    },
    marginB: {
      marginBottom: '10px',
    },
  }),
);

export interface ExternalProps {
  isLoading: boolean;
  gameUrl: any;
  path: string;
  user: ILoginResponse | null;
  gameRatings: any;
  userBalance: any;
  casinoLobby: ICasinoLobbyV2 | null;
}

export interface ExternalActionProps {
  addUpdateFavoriteGamePlayerAction: (x: any) => void;
  addUpdateGameRatingPlayerAction: (x: any) => void;
  clearCasinoLobbyAction: () => void;
  getCasinoLobbyAction: (x: IGetGameRequestDto) => void;
  getGameRatingAction: (x: any) => void;
  getUserBalanceAction: () => void;
  openCasinoGameAction: (x: any) => void;
  openCasinoGameActionV2: (x: any) => void;
}

export const LiveDealerComponent = (props: ExternalActionProps & ExternalProps) => {
  const {
    addUpdateFavoriteGamePlayerAction,
    addUpdateGameRatingPlayerAction,
    clearCasinoLobbyAction,
    casinoLobby,
    gameRatings,
    gameUrl,
    getCasinoLobbyAction,
    getGameRatingAction,
    getUserBalanceAction,
    isLoading,
    openCasinoGameAction,
    openCasinoGameActionV2,
    user,
    userBalance,
  } = props;
  const classes = useStyles();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [view, setView] = useState<number>(0);
  const [userDevice, setUserDevice] = useState<number>(1);
  const [localRights, setLocalRights] = useState<Right[]>([]);
  const [providerSelected, setProviderSelected] = useState<Right | null>(null);
  const [imageCDNUrl, setImageCDNUrl] = useState<string>('');
  const [isPortrait, SetIsPortrait] = useState<boolean>(true);
  const [useFreePlay, setUseFreePlay] = useState<boolean>(false);
  const [showGoBack, setShowGoBack] = useState<boolean>(true);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [firstLoad, setFirstLoad] = useState<boolean>(false);

  /*START COMPONENT*/
  useEffect(() => {
    const imagesCdn = getAppSettings().imagesCdn;
    setImageCDNUrl(imagesCdn);

    window.addEventListener(
      'orientationchange',
      function () {
        handleSetIsMobile();
      },
      false,
    );

    window.addEventListener('resize', () => {
      handleSetIsMobile();
    });

    const tmpMobile = handleSetIsMobile();
    let tmpDeviceId = 1;

    if (tmpMobile) {
      if (isAndroid) {
        tmpDeviceId = 3;
      }
      if (isIOS) {
        tmpDeviceId = 2;
      }
      if (isWinPhone) {
        tmpDeviceId = 4;
      }

      setUserDevice(tmpDeviceId);
    }

    return () => {
      clearCasinoLobbyAction();
    };
  }, []);

  useEffect(() => {
    if (user && user.rights) {
      let tmpDeviceId = userDevice;

      if (tmpDeviceId == -1) {
        const tmpMobile = handleSetIsMobile();

        if (tmpMobile) {
          if (isAndroid) {
            tmpDeviceId = 3;
          }
          if (isIOS) {
            tmpDeviceId = 2;
          }
          if (isWinPhone) {
            tmpDeviceId = 4;
          }
        }
      }

      getCasinoLobbyAction({
        categories: '',
        device_id: tmpDeviceId,
        providers: user.rights
          .filter(x => x.tpi_category_id == TPIProviderCategoryEnum.LIVE_DEALER)
          .map(x => x.id)
          .join(','),
        use_free_play: useFreePlay ? 1 : null,
      });
    }
  }, [user]);

  useEffect(() => {
    if (casinoLobby && casinoLobby.providers) {
      const tmpRights: Right[] = [];

      casinoLobby.providers.forEach(x => {
        if (x.tpi_provider_type_id == TPIProviderTypeEnum.GAMES) {
          casinoLobby.categories.forEach(y => {
            const searchGame = y.games.find(z => z.provider_id == x.id);
            const tmpRightSelected = tmpRights.find(z => z.id == x.id);
            if (searchGame && !tmpRightSelected) {
              tmpRights.push(x);
            }
          });
        } else if (x.tpi_provider_type_id == TPIProviderTypeEnum.LOBBY) {
          if (useFreePlay == false || (useFreePlay && x.use_free_play == 1)) {
            const tmpRightSelected = tmpRights.find(z => z.id == x.id);
            if (!tmpRightSelected) {
              tmpRights.push(x);
            }
          }
        }
      });

      setLocalRights(tmpRights);

      if (firstLoad == false) {
        handleDisplay(tmpRights);
        setFirstLoad(true);
      }
    }
    if (casinoLobby && casinoLobby.categories) {
      if (casinoLobby.categories.length == 0) {
        setShowMessage(true);
      }
    }
  }, [casinoLobby]);

  useEffect(() => {
    if (user && user.rights) {
      getCasinoLobbyAction({
        categories: '',
        device_id: userDevice,
        providers: user.rights
          .filter(x => x.tpi_category_id == TPIProviderCategoryEnum.LIVE_DEALER)
          .map(x => x.id)
          .join(','),
        use_free_play: useFreePlay ? 1 : null,
      });
    }
  }, [useFreePlay]);

  const handleSetIsMobile = () => {
    let tmpIsPortrait = true;
    if (window.matchMedia('(orientation: portrait)').matches) {
      SetIsPortrait(true);
      tmpIsPortrait = true;
    }
    if (window.matchMedia('(orientation: landscape)').matches) {
      SetIsPortrait(false);
      tmpIsPortrait = false;
    }
    const tmpIsMobile = (tmpIsPortrait == true ? window.innerWidth : window.innerHeight) <= 768 ? true : false;
    setIsMobile(tmpIsMobile);
    return tmpIsMobile;
  };

  const handleSelectedProvider = (provider: Right) => {
    setProviderSelected(provider);
  };

  const handleDisplay = (tmpRights: Right[]) => {
    const LiveDealerRights = tmpRights.filter(
      x => x.tpi_category_id == TPIProviderCategoryEnum.LIVE_DEALER && x.tpi_provider_type_id == TPIProviderTypeEnum.LOBBY,
    );
    const LiveDealerCasinoRights = tmpRights.filter(
      x => x.tpi_category_id == TPIProviderCategoryEnum.LIVE_DEALER && x.tpi_provider_type_id == TPIProviderTypeEnum.GAMES,
    );

    if (LiveDealerRights.length > 0 && LiveDealerCasinoRights.length > 0) {
      setView(LiveDealerViewEnum.LIVE_DEALER_AND_CASINO);
    } else if (LiveDealerRights.length > 0 && LiveDealerCasinoRights.length == 0) {
      if (LiveDealerRights.length == 1) {
        setView(LiveDealerViewEnum.LIVE_DEALER_ONLY);
        setProviderSelected(LiveDealerRights[0]);
      } else {
        getGameRatingAction(null);
        setView(LiveDealerViewEnum.LIVE_DEALER_AND_CASINO);
      }
    } else if (LiveDealerRights.length == 0 && LiveDealerCasinoRights.length > 0) {
      if (LiveDealerCasinoRights.length == 1) {
        setView(LiveDealerViewEnum.CASINO_ONLY);
        setProviderSelected(LiveDealerCasinoRights[0]);
      } else {
        getGameRatingAction(null);
        setView(LiveDealerViewEnum.LIVE_DEALER_AND_CASINO);
      }
    } else {
      setShowMessage(true);
      setView(LiveDealerViewEnum.ERROR_LIVE_DEALER_VIEW);
    }
  };

  const freePlayChange = () => {
    setUseFreePlay(!useFreePlay);
    setShowMessage(false);
  };

  return (
    <>
      <div className={classes.root + ' fontBeba'}>
        <Loading loading={isLoading} />
        <div className={classes.container}>
          {providerSelected != null && (
            <Grid container>
              {showGoBack && (view == LiveDealerViewEnum.LIVE_DEALER_AND_CASINO || view == LiveDealerViewEnum.CASINO_ONLY) && (
                <Grid item xs={12} md={6} className={classes.divProviderImage}>
                  <img src={`${imageCDNUrl}${providerSelected.image}`} className={classes.providerImage} />
                </Grid>
              )}
              {showGoBack && view == LiveDealerViewEnum.LIVE_DEALER_AND_CASINO && (
                <Grid item xs={12} md={6}>
                  <Button
                    onClick={() => setProviderSelected(null)}
                    style={{
                      maxWidth: '165px',
                      marginLeft: 'auto',
                      float: 'right',
                    }}
                    className={classes.bottonBack}
                  >
                    <FontAwesomeIcon style={{ marginRight: '10px' }} icon={['fad', 'arrow-circle-left']}></FontAwesomeIcon> Back
                  </Button>
                </Grid>
              )}
            </Grid>
          )}

          <Grid container spacing={2}>
            {providerSelected == null && view != LiveDealerViewEnum.ERROR_LIVE_DEALER_VIEW && (
              <Grid item xs={12}>
                <Typography align='center'>Live Dealer Providers</Typography>
              </Grid>
            )}
            {((providerSelected == null && view != LiveDealerViewEnum.ERROR_LIVE_DEALER_VIEW) ||
              (providerSelected && view == LiveDealerViewEnum.CASINO_ONLY)) && (
              <Grid item xs={12}>
                <div
                  className={`${isMobile ? classes.useFreePlayBalanceMobile : ''} ${classes.useFreeplayBox} ${
                    providerSelected && view == LiveDealerViewEnum.CASINO_ONLY ? classes.marginB : ''
                  } `}
                >
                  <Checkbox
                    color='primary'
                    checked={useFreePlay}
                    style={{ padding: '9px 2px 9px 0' }}
                    onChange={() => {
                      freePlayChange();
                    }}
                  />
                  <span className={classes.useBonusText}>{`Use Bonus Balance ($${GetAmountWithOutDecimals(
                    userBalance.FreePlayAmount ?? 0,
                  )})`}</span>
                </div>
              </Grid>
            )}
          </Grid>
          <Grid container spacing={2} justify='flex-start'>
            {providerSelected == null &&
              view != LiveDealerViewEnum.ERROR_LIVE_DEALER_VIEW &&
              localRights.map((right: Right) => {
                return (
                  <Grid
                    onClick={() => {
                      handleSelectedProvider(right);
                    }}
                    item
                    xs={6}
                    sm={6}
                    md={4}
                    lg={2}
                    key={right.id}
                  >
                    <a className='cardProvider credentialing' style={{ margin: 'auto' }}>
                      <div className='overlay'></div>
                      <div className='circle'>
                        <img src={`${imageCDNUrl}${right.image}`} className={classes.providerImageCard} />
                      </div>
                      <p>{right.description}</p>
                    </a>
                  </Grid>
                );
              })}
          </Grid>
          {isLoading == false && showMessage && (localRights.length == 0 || (casinoLobby && casinoLobby.categories.length == 0)) && (
            <>
              {useFreePlay && (
                <Typography className={classes.textAC} variant='h6'>
                  There are no games available to play using Bonus Balance.
                </Typography>
              )}
              {view == LiveDealerViewEnum.ERROR_LIVE_DEALER_VIEW && (
                <Typography className={classes.textAC} variant='h6'>
                  We&apos;re sorry, but there are no Live Dealer providers available at the moment.
                </Typography>
              )}
            </>
          )}
        </div>

        {user && casinoLobby && providerSelected && providerSelected.tpi_provider_type_id != TPIProviderTypeEnum.LOBBY && (
          <LiveDealerDisplayProvider
            addUpdateFavoriteGamePlayerAction={addUpdateFavoriteGamePlayerAction}
            addUpdateGameRatingPlayerAction={addUpdateGameRatingPlayerAction}
            gameRatings={gameRatings}
            gameUrl={gameUrl}
            getUserBalanceAction={getUserBalanceAction}
            imageCDNUrl={imageCDNUrl}
            isMobile={isMobile}
            isLoading={isLoading}
            isPortrait={isPortrait}
            is_freeplay={null}
            LiveDealerView={view}
            openCasinoGameAction={openCasinoGameAction}
            openCasinoGameActionV2={openCasinoGameActionV2}
            providerSelected={providerSelected}
            setShowGoBack={setShowGoBack}
            useFreePlay={useFreePlay}
            user={user}
            userBalance={userBalance}
            userDevice={userDevice}
            casinoLobby={casinoLobby}
          />
        )}

        {providerSelected && providerSelected.tpi_provider_type_id == TPIProviderTypeEnum.LOBBY && (
          <LiveDealerDisplayIframeComponent
            gameUrl={gameUrl}
            isLoading={isLoading}
            openCasinoGameAction={openCasinoGameAction}
            providerSelected={providerSelected}
            LiveDealerView={view}
            is_freeplay={0}
            userDevice={userDevice}
          />
        )}
      </div>
    </>
  );
};
