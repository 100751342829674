import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Tab, Tabs } from '@material-ui/core';
import { IRoute, RoutesDictionary, RoutesEnum } from '../../models/routes';
import { getHistory } from '../../utils/history.util';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import CardGiftcardSharpIcon from '@material-ui/icons/CardGiftcardSharp';
import HelpOutlineSharpIcon from '@material-ui/icons/HelpOutlineSharp';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import { PROVIDERS } from '../../enum/providers/providers';
import { IsValidRight, IsValidRightByTpiProviderCategory } from '../../utils';
import { VipFootBall } from '../icons/football-icon';
import { TPIProviderCategoryEnum } from '../../enum/tpi/TPIProviderCategoryEnum';

const useStyles = makeStyles(() => ({
  tab: {
    minWidth: 150,
    paddingLeft: '20px',
    paddingRight: '20px',
    position: 'relative',
  },
  appBg: {
    backgroundColor: '#2d2d2d',
  },
  faIcon: {
    fontSize: '1.5rem',
    color: 'white',
  },
  iconText: {
    fontSize: '.75rem',
    paddingTop: '.5rem',
  },
  appBar: {
    width: '100%',
    backgroundColor: '#2d2d2d',
    position: 'fixed',
    zIndex: 1025,
  },
}));

export interface NavBarProps {
  pathname: string;
  user: any;
}

const getIcon = (routeName: any) => {
  if (routeName == RoutesEnum.Sportsbook) return <VipFootBall />;
  // if (routeName == RoutesEnum.Sportsbook) {
  //   return <SportsFootballIcon style={{ fontSize: '2rem' }} />;
  // }
  if (routeName == RoutesEnum.LiveSports) {
    return <WhatshotIcon style={{ fontSize: '2rem', color: 'orange' }} />;
  }
  if (routeName == RoutesEnum.ESports) {
    return <SportsEsportsIcon style={{ fontSize: '2rem' }} />;
  }
  if (routeName == RoutesEnum.Casino) {
    return <FontAwesomeIcon icon={['fad', 'dice']} style={{ fontSize: '1.5rem', color: 'skyblue' }} />;
  }
  // if (routeName == RoutesEnum.Casino) {
  //   return <img style={{ background: '#FFF', width: '37px', height: '33px' }} src={`${process.env.PUBLIC_URL}/casino-lobby.png`} />;
  // }
  if (routeName == RoutesEnum.Live_Dealer)
    return <img style={{ width: '37px', height: '33px' }} src={`${process.env.PUBLIC_URL}/live-dealer.png`} />;

  if (routeName == RoutesEnum.Racebook || routeName == RoutesEnum.Horses) {
    return <FontAwesomeIcon icon={['fad', 'horse-head']} style={{ fontSize: '2rem', color: '#a36314' }} />;
  }
  if (routeName == RoutesEnum.Promotions) {
    return <CardGiftcardSharpIcon style={{ fontSize: '2rem' }} />;
  }
  if (routeName == RoutesEnum.RAF) {
    return <FontAwesomeIcon icon={['fad', 'user-friends']} style={{ fontSize: '2rem', color: 'orange' }} />;
  }
  if (routeName == RoutesEnum.Rules) {
    return <HelpOutlineSharpIcon style={{ fontSize: '2rem' }} />;
  }
  if (routeName == RoutesEnum.Guides) {
    return <MenuBookIcon style={{ fontSize: '2rem' }} />;
  }
  if (routeName == RoutesEnum.Cashier) {
    return <FontAwesomeIcon icon={['fad', 'sack-dollar']} style={{ fontSize: '1.5rem' }} />;
  }
  if (routeName == RoutesEnum.Account) {
    return <FontAwesomeIcon icon={['fad', 'user']} style={{ fontSize: '1.5rem' }} />;
  }
  if (routeName == RoutesEnum.Props_Builder)
    return <img style={{ width: '37px', height: '37px' }} src={`${process.env.PUBLIC_URL}/props.png`} />;

  if (routeName == RoutesEnum.Bracket) {
    return (
      <>
        <img style={{ width: '37px', height: '37px' }} src={`/images/icons/bracketIcon.png`} />
        <div className='triangle'>
          <span>New</span>
        </div>
      </>
    );
  }
  // if (routeName == RoutesEnum.Props_Builder) {
  //   return <FontAwesomeIcon icon={['fad', 'list-ol']} style={{ fontSize: '2rem' }} />;
  // }
  // Remove CasinoLobbyV2
  // if (routeName == RoutesEnum.CasinoLobbyV2) {
  //   return <FontAwesomeIcon icon={['fad', 'dice']} style={{ fontSize: '1.5rem', color: 'skyblue' }} />;
  // }
  return <Brightness1Icon style={{ fontSize: '2rem' }} />;
};

export const NavBar = ({ pathname, user }: NavBarProps) => {
  const classes = useStyles();

  const getTabs = (route: IRoute) => {
    return (
      <Tab
        key={route.name}
        label={route.name}
        icon={getIcon(route.name)}
        value={route.path}
        classes={{ root: classes.tab }}
        className={`tour_tabBar-${route['name'].replace(/\s/g, '').toLowerCase()}`}
      />
    );
  };

  let routes = [RoutesDictionary[RoutesEnum.Sportsbook]];
  if (IsValidRight(user, [PROVIDERS.PROPS_BUILDER])) routes = [...routes, RoutesDictionary[RoutesEnum.Props_Builder]];
  if (IsValidRight(user, [PROVIDERS.LIVE_WAGERING])) routes = [...routes, RoutesDictionary[RoutesEnum.LiveSports]];
  if (IsValidRight(user, [PROVIDERS.ESPORTS])) routes = [...routes, RoutesDictionary[RoutesEnum.ESports]];
  if (IsValidRight(user, [PROVIDERS.BETSOFT, PROVIDERS.CONCEPT, PROVIDERS.DGS_CASINO, PROVIDERS.VIG, PROVIDERS.PLATINUM]))
    // Remove CasinoLobbyV2
    routes = [...routes, RoutesDictionary[RoutesEnum.Casino]];
  if (IsValidRightByTpiProviderCategory(user, [TPIProviderCategoryEnum.LIVE_DEALER]))
    routes = [...routes, RoutesDictionary[RoutesEnum.Live_Dealer]];

  if (IsValidRight(user, [PROVIDERS.HORSES])) routes = [...routes, RoutesDictionary[RoutesEnum.Horses]];
  if (IsValidRight(user, [PROVIDERS.RACEBOOK])) routes = [...routes, RoutesDictionary[RoutesEnum.Racebook]];
  // routes = [...routes, RoutesDictionary[RoutesEnum.Promotions]];
  if (!user.agent_username) routes = [...routes, RoutesDictionary[RoutesEnum.RAF]];
  routes = [...routes, RoutesDictionary[RoutesEnum.Account]];
  routes = [...routes, RoutesDictionary[RoutesEnum.Rules]];
  // routes = [...routes, RoutesDictionary[RoutesEnum.Guides]];
  // routes = [...routes, RoutesDictionary[RoutesEnum.Bracket]];

  return (
    <div className={classes.appBar}>
      <Grid container direction='row' justify='center' alignItems='center'>
        <Tabs
          value={pathname}
          onChange={(event, newValue: number) => getHistory().push(newValue)}
          variant='scrollable'
          scrollButtons='on'
          indicatorColor='primary'
          textColor='primary'
          aria-label='scrollable force tabs example'
          className={classes.appBg}
        >
          {routes.map((route: IRoute) => getTabs(route))}
        </Tabs>
      </Grid>
    </div>
  );
};
