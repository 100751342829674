import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, Drawer, Fab, Grid, IconButton, Theme } from '@material-ui/core';
import { IRoute, RoutesDictionary, RoutesEnum } from '../../models/routes';
import { getHistory } from '../../utils/history.util';
// Icons
// import SportsFootballIcon from '@material-ui/icons/SportsFootball';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import CardGiftcardSharpIcon from '@material-ui/icons/CardGiftcardSharp';
import HelpOutlineSharpIcon from '@material-ui/icons/HelpOutlineSharp';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import CloseIcon from '@material-ui/icons/Close';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import { PROVIDERS } from '../../enum/providers/providers';
import { IsValidRight, IsValidRightByTpiProviderCategory } from '../../utils';
import { VipFootBall } from '../icons/football-icon';
import { TPIProviderCategoryEnum } from '../../enum/tpi/TPIProviderCategoryEnum';

declare global {
  interface Window {
    LO: any;
  }
}

// const openLOLiveChat = () => {
//   if (window.LO != null) {
//     if (window.LO.messenger != undefined && window.LO.messenger != null) {
//       window.LO.messenger.open();
//       console.log('opened LO Chat');
//     }
//   }
// };

const useStyles = makeStyles((theme: Theme) => ({
  iconButtonLabel: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '1rem',
    fontWeight: 'bold',
    '& svg': {
      paddingBottom: '5px',
    },
  },
  label: {
    fontSize: '1rem',
    // textTransform: 'uppercase',
  },
  toolbar: {
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    backgroundImage: 'url(' + `${process.env.PUBLIC_URL}/top-bg.png` + ')',
    borderTop: '1px solid #333',
  },
  toolbar2: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    // backgroundColor: '#1f4d72',
    backgroundImage: 'url(' + `${process.env.PUBLIC_URL}/top-bg.png` + ')',
  },
  toolbarItem: {
    width: '20%',
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    backgroundColor: '#1f2020',
    position: 'fixed',
    zIndex: 1201,
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: -10,
    left: 0,
    right: 0,
    margin: '0 auto',
  },
  ButtonMenuDescriptions: {
    fontSize: '1.0rem',
  },
  ButtonMenuDescriptionsLiveDealer: {
    fontSize: '0.75rem',
  },
  iconWrapper: {
    textAlign: 'center',
    padding: 5,
  },
  bottomDrawar: {
    '& .MuiDrawer-paper': {
      overflowY: 'visible',
    },
    '& .MuiDrawer-paperAnchorBottom': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  chatIcon: {
    color: theme.palette.primary.main,
  },
  menutooIcon: {
    width: '50% !important',
    marginLeft: '0px !important',
    marginRight: '0px !important',
  },
}));

const getIcon = (routeName: any) => {
  // RoutesEnum.LoginPage;
  if (routeName == RoutesEnum.Sportsbook) {
    return <VipFootBall />;
    // return <SportsFootballIcon style={{ fontSize: '2rem' }} color='primary' />;
  }
  if (routeName == RoutesEnum.LiveSports) {
    return <WhatshotIcon style={{ height: '38px', fontSize: '2rem', color: 'orange' }} />;
  }

  if (routeName == RoutesEnum.Casino) {
    return <FontAwesomeIcon icon={['fad', 'dice']} style={{ fontSize: '1.5rem', color: 'skyblue' }} />;
  }
  // if (routeName == RoutesEnum.Casino) {
  //   return <img style={{ background: '#FFF', width: '37px', height: '33px' }} src={`${process.env.PUBLIC_URL}/casino-lobby.png`} />;
  // }
  if (routeName == RoutesEnum.Live_Dealer)
    return <img style={{ width: '27px', height: '25px' }} src={`${process.env.PUBLIC_URL}/live-dealer.png`} />;

  if (routeName == RoutesEnum.ESports) {
    return <SportsEsportsIcon style={{ fontSize: '2rem' }} />;
  }

  if (routeName == RoutesEnum.Promotions) {
    return <CardGiftcardSharpIcon style={{ fontSize: '2rem' }} />;
  }
  if (routeName == RoutesEnum.RAF) {
    return <FontAwesomeIcon icon={['fad', 'user-friends']} style={{ fontSize: '1.5rem' }} />;
  }
  if (routeName == RoutesEnum.Rules) {
    return <HelpOutlineSharpIcon style={{ fontSize: '2rem' }} />;
  }
  if (routeName == RoutesEnum.Guides) {
    return <MenuBookIcon style={{ fontSize: '2rem' }} />;
  }
  if (routeName == RoutesEnum.Cashier) {
    return <FontAwesomeIcon icon={['fad', 'sack-dollar']} style={{ fontSize: '1.5rem' }} />;
  }
  if (routeName == RoutesEnum.LiveChat) {
    return <FontAwesomeIcon icon={['fad', 'comments']} style={{ fontSize: '2rem', color: '#fff' }} />;
  }
  if (routeName == RoutesEnum.Account) {
    return <FontAwesomeIcon icon={['fad', 'user']} style={{ fontSize: '2rem' }} />;
  }
  // if (routeName == RoutesEnum.Props_Builder) return <FontAwesomeIcon icon={['fad', 'list-ol']} style={{ fontSize: '2rem' }} />;
  if (routeName == RoutesEnum.Props_Builder)
    return <img style={{ width: '37px', height: '37px' }} src={`${process.env.PUBLIC_URL}/props.png`} />;

  if (routeName == RoutesEnum.Racebook || routeName == RoutesEnum.Horses)
    return <FontAwesomeIcon icon={['fad', 'horse-head']} style={{ fontSize: '2rem', color: '#a36314' }} />;
  return <Brightness1Icon style={{ fontSize: '2rem' }} />;
};

export interface AppRootProps {
  isTVOn: boolean;
  tvUrl: string;
  user: any;
  rightOpen: boolean;
}
export interface ExternalActionProps {
  tvOnOffAction: (isOn: boolean) => void;
  setTvUrlAction: (ch: string) => void;
}

export const NavBarBottom = ({ user, rightOpen }: ExternalActionProps & AppRootProps) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const classes = useStyles();
  const getName = (name: string) => {
    switch (name) {
      case 'Sportsbook':
        return 'Sports';
      case 'Props Builder':
        return 'Props';
      case RoutesEnum.Live_Dealer:
        return 'Live </br> Dealer';
      default:
        return name;
    }
  };

  const getIconButton = (route: IRoute) => {
    const shouldAddMenuTooIcon = routes && routes.filter(x => x.path != RoutesEnum.Hidden).length == 2;

    return (
      <IconButton
        className={`btnMenuBotton font-theme ${classes.iconButtonLabel} ${
          shouldAddMenuTooIcon ? classes.menutooIcon : ''
        }  tour_tabBar-${route['name'].replace(/\s/g, '').toLowerCase()}`}
        classes={{ root: classes.toolbarItem, label: classes.iconButtonLabel }}
        key={route.name}
        style={
          route.path == RoutesEnum.Hidden
            ? shouldAddMenuTooIcon
              ? { display: 'none', width: '0%' }
              : { visibility: 'hidden', width: '0%' }
            : routes && routes.length >= 4 && routes[0].name == RoutesEnum.Hidden && routes[3].name == route.name
            ? { marginLeft: '3rem', marginRight: '-2rem' }
            : {}
        }
        onClick={() => {
          setOpenDrawer(false);
          getHistory().push(route.path);
        }}
      >
        {getIcon(route.name)}
        <span
          className={`${route.name == RoutesEnum.Live_Dealer ? classes.ButtonMenuDescriptionsLiveDealer : classes.ButtonMenuDescriptions}`}
          dangerouslySetInnerHTML={{ __html: getName(route.name) }}
        ></span>
      </IconButton>
    );
  };

  const getButton = (route: IRoute) => {
    return (
      <Grid key={route.name} item xs={4}>
        <div className={`${classes.iconWrapper} tour_tabBar-${route['name'].replace(/\s/g, '').toLowerCase()}`}>
          <IconButton
            classes={{ label: classes.iconButtonLabel }}
            onClick={() => {
              setOpenDrawer(false);
              getHistory().push(route.path);
            }}
          >
            {getIcon(route.name)}

            <span className={classes.ButtonMenuDescriptions}>{route.name}</span>
          </IconButton>
        </div>
      </Grid>
    );
  };

  let localRoutes = [RoutesDictionary[RoutesEnum.Sportsbook]];

  if (IsValidRight(user, [PROVIDERS.PROPS_BUILDER])) localRoutes = [...localRoutes, RoutesDictionary[RoutesEnum.Props_Builder]];

  if (IsValidRight(user, [PROVIDERS.LIVE_WAGERING])) localRoutes = [...localRoutes, RoutesDictionary[RoutesEnum.LiveSports]];

  if (IsValidRightByTpiProviderCategory(user, [TPIProviderCategoryEnum.LIVE_DEALER]))
    localRoutes = [...localRoutes, RoutesDictionary[RoutesEnum.Live_Dealer]];

  if (IsValidRight(user, [PROVIDERS.BETSOFT, PROVIDERS.CONCEPT, PROVIDERS.DGS_CASINO, PROVIDERS.PLATINUM, PROVIDERS.VIG]))
    localRoutes = [...localRoutes, RoutesDictionary[RoutesEnum.Casino]];

  let routes: IRoute[] = [];
  if (localRoutes.length < 5) {
    let cont = localRoutes.length;
    while (cont < 5) {
      if (routes?.length > 0) {
        routes = [RoutesDictionary[RoutesEnum.Hidden]];
      }
      routes = [...routes, RoutesDictionary[RoutesEnum.Hidden]];
      cont++;
    }
  }
  routes = [...routes, ...localRoutes];
  let drawerRoutes: any[] = [];
  // if (IsValidRight(user, [PROVIDERS.LIVE_WAGERING])) drawerRoutes = [...drawerRoutes, RoutesDictionary[RoutesEnum.LiveSports]];
  if (IsValidRight(user, [PROVIDERS.HORSES])) drawerRoutes = [...drawerRoutes, RoutesDictionary[RoutesEnum.Horses]];

  if (IsValidRight(user, [PROVIDERS.RACEBOOK])) drawerRoutes = [...drawerRoutes, RoutesDictionary[RoutesEnum.Racebook]];

  if (!user.agent_username) drawerRoutes = [...drawerRoutes, RoutesDictionary[RoutesEnum.RAF]];
  drawerRoutes = [...drawerRoutes, RoutesDictionary[RoutesEnum.Account]];
  drawerRoutes = [...drawerRoutes, RoutesDictionary[RoutesEnum.Rules]];
  // drawerRoutes = [...drawerRoutes, RoutesDictionary[RoutesEnum.Guides]];
  // drawerRoutes = [...drawerRoutes, RoutesDictionary[RoutesEnum.LiveChat]];
  // drawerRoutes = [...drawerRoutes, RoutesDictionary[RoutesEnum.Promotions]];
  // drawerRoutes = [...drawerRoutes, RoutesDictionary[RoutesEnum.Bracket]];
  // className={`fonttest ${classes.iconButtonLabel}`}

  return (
    <AppBar color='primary' className={`${classes.appBar} ${rightOpen ? 'animationHDown' : 'animationHUp'}`}>
      <Toolbar classes={{ root: classes.toolbar }}>
        {routes.map((route: IRoute) => getIconButton(route))}
        <Fab
          color='secondary'
          aria-label='add'
          className={`${classes.fabButton} tour_mobile_more_options`}
          onClick={() => setOpenDrawer(true)}
        >
          <FontAwesomeIcon icon={['fad', 'bars']} style={{ fontSize: '1.5rem' }} />
        </Fab>
      </Toolbar>
      <Drawer anchor='bottom' open={openDrawer} onClose={() => setOpenDrawer(false)} className={classes.bottomDrawar}>
        <Toolbar classes={{ root: classes.toolbar2 }}>
          {routes.map((route: IRoute) => getIconButton(route))}
          <Fab
            color='primary'
            aria-label='add'
            className={`${classes.fabButton} tour_mobile_more_options`}
            onClick={() => setOpenDrawer(false)}
          >
            <CloseIcon style={{ fontSize: '1.5rem' }} />
          </Fab>
        </Toolbar>
        <Grid container spacing={3} style={{ padding: 20 }}>
          {drawerRoutes.map((route: IRoute) => getButton(route))}
          {/* <Grid key={'tv'} item xs={4}>
            <div className={classes.iconWrapper}>
              <IconButton classes={{ label: classes.iconButtonLabel }} onClick={() => tvOnOffAction(!isTVOn)}>
                <VipLiveTv style={{ width: '2rem', height: '2rem' }} />
                TV
              </IconButton>
            </div>
          </Grid> */}
          <Grid key={'tv'} item xs={4}>
            <div className={classes.iconWrapper}>
              <IconButton
                classes={{ label: classes.iconButtonLabel }}
                onClick={() => {
                  setOpenDrawer(false);
                  // openLOLiveChat();
                  getHistory().push('live-chat');
                }}
              >
                <FontAwesomeIcon icon={['fad', 'comments']} style={{ fontSize: '2rem', color: '#fff' }} /> Support
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </Drawer>
    </AppBar>
  );
};
